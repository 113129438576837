import './App.css';
import React from "react";

function SignIn() {
  return (
    <div>
        <h1>Sign in Page</h1>
    </div>
  );
}

export default SignIn;
